import React, { useState, useEffect, useRef } from "react"
import "./banner.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import CustomMarkdown from "../../utils/customMarkdown"

const Banner = ({ title, subtitle, text, imagePosition, image, imageAlt, dark = true, topPadding, bottomPadding, sticky, mobileStickiness }) => {

    const [scrolled, setScrolled] = useState(false)

    function showHideBanner() {

        var y = window.scrollY;
        const refElement = bannerRef.current;

        if (refElement) {

            if (y + window.innerHeight < bannerRef.current.offsetTop && y < bannerRef.current.offsetTop || y + window.innerHeight > bannerRef.current.offsetTop && y > bannerRef.current.offsetTop) {
                setScrolled(true)
            } else {
                setScrolled(false)
            }
        }

    }

    useEffect(() => {
        window.addEventListener("scroll", showHideBanner)
    }, [])


    const bannerRef = useRef(null);

    let newTextH2 = "";
    let newTextH3 = "";
    let newTextCustom = "";
    let newTextCustomNormal = "";

    if (text.split('#').length > 2 && text.indexOf('#') > 1) {
        newTextH2 = text.split('#')[0]
        newTextH3 = text.split('#')[1]
        if (text.split('#')[2]) {
            newTextCustom = text.split('#')[2]
        }
    } else {
        if (text.split('#').length > 1 && text.indexOf('#') > 1) {
            newTextH2 = text.split('#')[0]
            newTextCustom = text.split('#')[1]
        } else {
            newTextCustomNormal = text
        }
    }

    // if (imagePosition == null) {
    //     imagePosition = "left"
    // }

    return (
        <>
            <div className={"price-guarantee" + `${dark ? " dark" : ""} ${topPadding ? " padding-top" : ""} ${bottomPadding ? " padding-bottom" : ""}` + " lateral-padding"}>

                <div className="price-guarantee__content uk-align-center padding-top padding-bottom" data-uk-grid ref={sticky ? bannerRef : null} id="sticky-banner">
                    <div className="no-padding-left align-content">
                        {(image !== null && imagePosition === "left") ?
                            image !== "" ?
                                <div className="uk-width-1-3@s price-guarantee__content__image__left">
                                    <GatsbyImage image={image?.gatsbyImageData} alt="Cheap Price Stamp" style={{ maxWidth: '200px' }}></GatsbyImage>
                                </div>
                                : ""
                            : ""}

                        <div className={imagePosition == null ? "center-align uk-width-1-1@s uk-align-center" : imagePosition == "right" ? "right-align uk-width-2-3@s uk-align-center" : "left-align uk-width-2-3@s uk-align-center"}>
                            {title ? <h2 className="banner-title">{title} </h2> : ""}
                            {subtitle ? <span className="price-guarantee-subtitle">{subtitle}</span> : ""}

                            <div className={imagePosition == null ? "text-description-box-centered" : imagePosition == "right" ? "text-description-box-right" : "text-description-box-left"}>
                                {newTextH2 != "" ? <h2 className="text-title-split">{newTextH2}</h2> : ""}
                                {newTextH3 != "" ? <h3 className="text-second-split">{newTextH3}</h3> : ""}
                                {newTextCustom != "" ? <CustomMarkdown children={newTextCustom} skipHtml={false} className={imagePosition == "right" ? "custom-markdown-btn-right" : "custom-markdown-btn"} id="content-text"></CustomMarkdown> : ""}
                                {newTextCustomNormal != "" ? newTextCustomNormal.includes('::') ?
                                    <CustomMarkdown children={newTextCustomNormal} skipHtml={false} className="price-guarantee__content__text__normal" id="content-text"></CustomMarkdown>
                                    : <p>{newTextCustomNormal}</p>
                                    : ""}
                            </div>
                        </div>

                        {(image !== "" && imagePosition === "right") ?
                            image !== null ?
                                <div className="uk-width-1-3@s price-guarantee__content__image__right">
                                    <GatsbyImage image={image?.gatsbyImageData} alt="Cheap Price Stamp" style={{ maxWidth: '200px' }}></GatsbyImage>
                                </div>
                                : ""
                            : ""}
                    </div>
                </div>

                {
                    sticky && scrolled ?
                        <div className={mobileStickiness ? "mobile-banner" : "bottom-banner uk-visible@m"} data-uk-scrollspy="cls:uk-animation-fade">
                            <div className={mobileStickiness ? "text-description-box-mobile" : "text-description-box"}>
                                <CustomMarkdown children={newTextH2} skipHtml={false} className="price-guarantee__content__text" id="content-text"></CustomMarkdown>
                                {newTextCustom != "" ? <CustomMarkdown children={newTextCustom} skipHtml={false} className="noimg-banner-btn" id="content-text"></CustomMarkdown> : ""}
                                {newTextCustomNormal != "" ? <CustomMarkdown children={text} skipHtml={false} className="price-guarantee__content__text" id="content-text"></CustomMarkdown> : ""}
                            </div>
                        </div>
                        : ""
                }

            </div>
        </>
    )

}

export default Banner