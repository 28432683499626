import React from "react"
import "./html-editor.scss"

const HtmlEditor = ({ htmlContent, fullWidth, topPadding, bottomPadding }) => {

    return (
        <>
            <div className={`${topPadding ? ' padding-top ' : ''} ${bottomPadding ? ' padding-bottom ' : ''}`}>
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} className={!fullWidth ? "html-editor html-editor-boxedWidth" : 'html-editor'}>
                </div>
            </div>
        </>
    )

}

export default HtmlEditor