import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import GetImgUrl from "../utils/customFunctions"
import checkPromoValue from "../utils/checkPromoValue"
import useSiteMetadata from "../utils/useSiteMetadata"
//import loadable from '@loadable/component'

import RichSnippet from "../components/rich-snippet-component/rich-snippet"
import Header from "../components/header/header"
import Banner from "../components/banner/banner"
import Reviews from "../components/reviews/reviews"
import Paragraph from "../components/paragraph/paragraph"
import Accordion from "../components/accordion/accordion"
import CTAList from "../components/cta-list/ctaList"
import CTABanner from "../components/cta-banner/cta-banner"
import Providers from "../components/provider-list/providers"
import Comparison from "../components/comparison/comparison"
import HtmlEditor from "../components/html-editor/html-editor"

import ProviderCarousel from "../components/provider-carousel/provider-carousel"
import SingleImage from "../components/single-image/single-image"
import QuickLinkBlock from "../components/quick-link-block/quick-link-block"
import LinkCards from "../components/link-cards/link-cards"
import ParagraphColumn from "../components/paragraph-column/paragraph-column"
import RelatedArticles from "../components/related-articles/related-articles"
import TIHeader from "../components/ti-header/ti-header"


/*
const RichSnippet = loadable(() => import("../components/rich-snippet-component/rich-snippet"))
const Header = loadable(() => import("../components/header/header"))
const Banner = loadable(() => import("../components/banner/banner"))
const Reviews = loadable(() => import("../components/reviews/reviews"))
const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
const Accordion = loadable(() => import("../components/accordion/accordion"))
const CTAList = loadable(() => import("../components/cta-list/ctaList"))
const CTABanner = loadable(() => import("../components/cta-banner/cta-banner"))
const Providers = loadable(() => import("../components/provider-list/providers"))
const Comparison = loadable(() => import("../components/comparison/comparison"))
const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))

const ProviderCarousel = loadable(() => import("../components/provider-carousel/provider-carousel"))
const SingleImage = loadable(() => import("../components/single-image/single-image"))
//const QuickLinkBlock = loadable(() => import("../components/quick-link-block/quick-link-block"))
const LinkCards = loadable(() => import("../components/link-cards/link-cards"))
const ParagraphColumn = loadable(() => import("../components/paragraph-column/paragraph-column"))
const RelatedArticles = loadable(() => import("../components/related-articles/related-articles"))

*/



// markup

const IndexPage = ({ pageContext }) => {

  /*
  const [RichSnippet, setRichSnippet] = useState(null);

  useEffect(() => {
    import('../components/rich-snippet-component/rich-snippet').then((module) => {
      setRichSnippet(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [Banner, setBanner] = useState(null);

  useEffect(() => {
    import('../components/banner/banner').then((module) => {
      setBanner(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [Header, setHeader] = useState(null);

  useEffect(() => {
    import('../components/header/header').then((module) => {
      setHeader(() => module.default); // Wrap in a function to pass props
    });
 //    setHeader(() =>loadable(() => import("../components/header/header")));
  }, []);

  
  const [Reviews, setReviews] = useState(null);

  useEffect(() => {
    import('../components/reviews/reviews').then((module) => {
      setReviews(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [Paragraph, setParagraph] = useState(null);

  useEffect(() => {
    import('../components/paragraph/paragraph').then((module) => {
      setParagraph(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [Accordion, setAccordion] = useState(null);

  useEffect(() => {
    import('../components/accordion/accordion').then((module) => {
      setAccordion(() => module.default); // Wrap in a function to pass props
    });
  }, []);

    const [CTAList, setCTAList] = useState(null);

  useEffect(() => {
    import('../components/cta-list/ctaList').then((module) => {
      setCTAList(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [CTABanner, setCTABanner] = useState(null);

  useEffect(() => {
    import('../components/cta-banner/cta-banner').then((module) => {
      setCTABanner(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [Providers, setProviders] = useState(null);

  useEffect(() => {
    import('../components/provider-list/providers').then((module) => {
      setProviders(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [Comparison, setComparison] = useState(null);

  useEffect(() => {
    import('../components/comparison/comparison').then((module) => {
      setComparison(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [HtmlEditor, setHtmlEditor] = useState(null);

  useEffect(() => {
    import('../components/html-editor/html-editor').then((module) => {
      setHtmlEditor(() => module.default); // Wrap in a function to pass props
    });
  }, []);
  

  const [ProviderCarousel, setProviderCarousel] = useState(null);

  useEffect(() => {
    import('../components/provider-carousel/provider-carousel').then((module) => {
      setProviderCarousel(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [SingleImage, setSingleImage] = useState(null);

  useEffect(() => {
    import('../components/single-image/single-image').then((module) => {
      setSingleImage(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [LinkCards, setLinkCards] = useState(null);

  useEffect(() => {
    import('../components/link-cards/link-cards').then((module) => {
      setLinkCards(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [ParagraphColumn, setParagraphColumn] = useState(null);

  useEffect(() => {
    import('../components/paragraph-column/paragraph-column').then((module) => {
      setParagraphColumn(() => module.default); // Wrap in a function to pass props
    });
  }, []);

  const [RelatedArticles, setRelatedArticles] = useState(null);

  useEffect(() => {
    import('../components/related-articles/related-articles').then((module) => {
      setRelatedArticles(() => module.default); // Wrap in a function to pass props
    });
  }, []);
  */

  const showElement = true;
  /*
  const [showElement, setShowElement] = useState(false)
  useEffect(() => {
      setTimeout(function () {
          setShowElement(true)
      }, 170);
  },
      [])
      */


  const data = useStaticQuery(graphql`
        {
          strapiHomepage {
            PageComponents
            SEOTitle
            SEODescription
            Canonical
            FooterDisclaimer
            MetaTags{
              MetaName
              MetaContent
            }
          }

          strapiSiteSettings {
              LogoDark {
                url
                localFile {
                  publicURL
                }
              }
              SiteTitle
              SocialLinks {
                FacebookURL
                LinkedinURL
                TwitterURL
                YoutubeURL
              }
          }
        }
    `)


  const { siteUrl: url } = useSiteMetadata()

  let richSnippetData = {
    '@context': "https://schema.org",
    "@type": "Organization",
    name: `${data.strapiSiteSettings.SiteTitle}`,
    url: `${url}`,
    logo: `${url + data.strapiSiteSettings.LogoDark.localFile.publicURL}`,
    sameAs: [data.strapiSiteSettings.SocialLinks.FacebookURL, data.strapiSiteSettings.SocialLinks.TwitterURL, data.strapiSiteSettings.SocialLinks.LinkedinURL, data.strapiSiteSettings.SocialLinks.YoutubeURL]
  }



  return (
    <Layout
      SEOTitle={data.strapiHomepage.SEOTitle}
      SEODescription={data.strapiHomepage.SEODescription}
      footerDisclaimer={data.strapiHomepage.FooterDisclaimer}
      meta={data.strapiHomepage.MetaTags}
      canonical={data.strapiHomepage.Canonical ? data.strapiHomepage.Canonical : `${url}`}>
      {data.strapiHomepage.PageComponents.map((item, index) => {


        switch (item.strapi_component) {

          case "page-components.ti-header":
            return (
              <>
                {RichSnippet && <RichSnippet type={"Organization"} data={richSnippetData} />}
                {TIHeader && <TIHeader key={index}
                  UpperSubtitle={item.UpperSubtitle}
                  MiddleText={item.MiddleText ? item.MiddleText : null}
                  Title={item.Title}
                  Text={item.Text ? item.Text : null}
                  CardBgColor={item.CardBgColor ? item.CardBgColor : null}
                  SecondColumnType={item.SecondColumnType}
                  Image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                  ImagePosition={item.ImagePosition ? item.ImagePosition : null}
                  BackgroundColor={item.BackgroundColor}
                  EnableMask={item.EnableMask}
                ></TIHeader>
                }
              </>

            )

          case "page-components.header":
            return (
              <>
                {RichSnippet && <RichSnippet type={"Organization"} data={richSnippetData} />}
                {Header && <Header key={index}
                  title={item.Title}
                  subtitle={item.Subtitle}
                  secondarySubtitle={item.SecondSubtitle}
                  CTAText={item.CTALabel}
                  CTAUrl={item.CTAUrl}
                  CTA_nofollow={item.CTA_nofollow}
                  Disclaimer={item.Disclaimer}
                  Background={GetImgUrl(item.Background.localFile___NODE, pageContext.images)}
                  HeaderHeight={item.HeaderHeight}
                  TextAlign={item.TextAlign}
                ></Header>
                }
              </>

            )

          case "page-components.ratings":
            return (
              <>
                {showElement && Reviews && <Reviews key={index}
                  text={item.Text}
                  ratingValue={item.RatingValue}
                  ratingCount={item.RatingCount}
                  reviewCount={item.ReviewCount}
                  ratingDisclaimer={item.RatingDisclaimer}
                  enableRichSnippet={item.EnableRichSnippet}
                  starImage={GetImgUrl(item.StarImage?.localFile___NODE, pageContext.images).gatsbyImageData}
                  reviewerLogo={GetImgUrl(item.ReviewerLogo?.localFile___NODE, pageContext.images).gatsbyImageData}
                ></Reviews>
                }
              </>
            )
          case "page-components.providers-carousel":
            var Images = []
            if (ProviderCarousel) {
              item.ImageList.map((imgItem, index) => {
                Images.push(GetImgUrl(imgItem.Image.localFile___NODE, pageContext.images))
              })
            }

            return (
              <>
                {showElement && ProviderCarousel && <ProviderCarousel key={index}
                  images={Images}
                  title={item.Title}
                  subtitle={item.Subtitle}
                  ctaUrl={item.CTAUrl}
                  ctaLabel={item.CTALabel}
                  backgroundColor={item.BackgroundColor}
                  enableMask={item.EnableMask}
                  imagesWhiteBg={item.ImagesWhiteBg}
                  imageType={item.ImageType}
                ></ProviderCarousel>
                }
              </>

            )
          case "page-components.comparison":
            return (
              <>
                {showElement && Comparison && <Comparison key={index}
                  title={item.Title}
                  step1={item.Step1}
                  step2={item.Step2}
                  step3={item.Step3}
                  image1={item.Image1 !== null ? GetImgUrl(item.Image1.localFile___NODE, pageContext.images) : null}
                  image2={item.Image2 !== null ? GetImgUrl(item.Image2.localFile___NODE, pageContext.images) : null}
                  image3={item.Image3 !== null ? GetImgUrl(item.Image3.localFile___NODE, pageContext.images) : null}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                  displayNumbers={(item.DisplayNumbers === null || item.DisplayNumbers === true) ? true : false}
                ></Comparison>
                }
              </>

            )


          case "page-components.cta-banner":
            return (
              <>
                {showElement && CTABanner && <CTABanner
                  key={index}
                  text={checkPromoValue(item.Text, pageContext.promoInfo)}
                  ctaUrl={item.CTAUrl}
                  ctaLabel={item.CTALabel}
                  secondaryCtaUrl={item.SecondaryCTAUrl}
                  secondaryCtaLabel={item.SecondaryCTALabel}
                  bgColorHex={item.BackgroundColorHEX}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                ></CTABanner>
                }
              </>

            )

          case "page-components.banner":
            return (


              <div>
                {showElement && Banner && <Banner
                  key={index}
                  title={checkPromoValue(item.Title, pageContext.promoInfo)}
                  subtitle={checkPromoValue(item.Subtitle, pageContext.promoInfo)}
                  text={checkPromoValue(item.Text, pageContext.promoInfo)}
                  image={item.Image != null && item.Text.includes("[*promoimage*]") ?
                    GetImgUrl(pageContext.settingsImage, pageContext.images) :
                    item.Image != null ?
                      GetImgUrl(item.Image?.localFile___NODE, pageContext.images) :
                      item.Text.includes("[*promoimage*]") ?
                        GetImgUrl(pageContext.settingsImage, pageContext.images)
                        : null}
                  imagePosition={item.ImagePosition}
                  dark={item.DarkBackground}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                  sticky={(item.Sticky === null || item.Sticky === false) ? false : true}
                  mobileStickiness={(item.MobileStickiness === null || item.MobileStickiness === false) ? false : true}
                ></Banner>
                }
              </div>






            )

          case "page-components.paragraph":
            return (
              <>
                {showElement && Paragraph && <Paragraph
                  key={index}
                  title={item.Title ? item.Title : ""}
                  text={item.Text}
                  image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images) : null}
                  imagePosition={item.ImagePosition}
                  bgColor={item.BackgroundColor || ""}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                  alignment={item.ContentAlignment}
                  initialPadding={item.InitialPadding}
                ></Paragraph>
                }
              </>
            )

          case "page-components.fa-qs":
            return (
              <>
                {showElement && Accordion && <Accordion
                  key={index}
                  title={item.Title ? item.Title : ""}
                  contents={item.QuestionAnswer}
                  enableSnippet={(item.EnableSnippet === null || item.EnableSnippet === false) ? false : true}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                ></Accordion>
                }
              </>

            )

          case "page-components.providers":
            var ImageList = []
            if (Providers) {
              item.Images.map((imgItem, index) => {
                ImageList.push(GetImgUrl(imgItem.localFile___NODE, pageContext.images))
              })
            }
            return (
              <>
                {showElement && Providers && <Providers
                  key={index}
                  text={item.Title}
                  image={ImageList}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false} />
                }
              </>

            )

          case "page-components.cta-list":
            var contentsList = []
            if (CTAList) {
              item.CTAList.map((CTAitem, index) => {
                var imageUrl = CTAitem.Image !== null ? GetImgUrl(CTAitem.Image.localFile___NODE, pageContext.images) : ""
                contentsList.push({
                  Title: CTAitem.Title,
                  URL: CTAitem.URL,
                  URLLabel: CTAitem.URLLabel,
                  Image: imageUrl,
                  Alt: CTAitem.Image.alternativeText,
                  Description: CTAitem.Description,
                  DescriptionCharLimit: CTAitem.DescriptionCharLimit
                })
                return ("")
              })
            }
            return (
              <>
                {showElement && CTAList && <CTAList
                  key={index}
                  title={item.Title ? item.Title : ""}
                  subtitle={item.Subtitle}
                  contents={contentsList}
                  cardStyle={item.CardStyle}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                ></CTAList>
                }
              </>

            )

          case "page-components.html-editor":
            return (
              <>
                {showElement && HtmlEditor && <HtmlEditor
                  key={index}
                  htmlContent={item.HTML}
                  fullWidth={item.FullWidth}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                ></HtmlEditor>
                }
              </>
            )

          case "page-components.image":
            return (
              <>
                {showElement && SingleImage && <SingleImage
                  image={item.Image !== null ? GetImgUrl(item.Image.localFile___NODE, pageContext.images).gatsbyImageData : null}
                  altText={item.Image !== null ? item.Image.alternativeText : null}
                  topPadding={(item.TopPadding === null || item.TopPadding === true) ? true : false}
                  bottomPadding={(item.BottomPadding === null || item.BottomPadding === true) ? true : false}
                  imgWidth={item.ImgWidth}
                ></SingleImage>
                }
              </>
            )

          case "page-components.link-cards":
            return (
              <>
                {showElement && LinkCards && <LinkCards
                  key={index}
                  title={item.Title}
                  bgColor={item.BackgroundColor}
                  cards={item.Cards}
                />
                }
              </>
            )

          case "page-components.paragraph-column":

            return (
              <>
                {showElement && ParagraphColumn && <ParagraphColumn
                  title={item.Title}
                  bgColor={item.BackgroundColor}
                  paragraphLeft={item.ParagraphLeft}
                  imageLeft={item.ParagraphLeft && item.ParagraphLeft.Image !== null ? GetImgUrl(item.ParagraphLeft.Image.localFile___NODE, pageContext.images) : ""}
                  paragraphRight={item.ParagraphRight}
                  imageRight={item.ParagraphRight && item.ParagraphRight.Image !== null ? GetImgUrl(item.ParagraphRight.Image.localFile___NODE, pageContext.images) : ""}
                />
                }
              </>
            )

          case "page-components.related-articles":

            var articlesList = []

            if (item.articles.length == 0 && RelatedArticles) {
              pageContext.articles.slice(0, 3).map((item, index) => {
                articlesList.push({
                  Title: item.node.Title,
                  slug: item.node.slug,
                  FeaturedImage: item.node.FeaturedImage,
                  CategorySlug: item.node.blog_category.CategorySlug,
                  CategoryName: item.node.blog_category.CategoryName,
                  Excerpt: item.node.Excerpt,
                  blog_category: item.node.blog_category.CategoryName
                })
                return ("")
              })
            }

            return (
              <>
                {showElement && RelatedArticles && <RelatedArticles
                  title={item.Title}
                  layout={item.Layout}
                  ctaLabel={item.CTALabel ? item.CTALabel : ""}
                  ctaUrl={item.CTAUrl ? item.CTAUrl : ""}
                  articles={item.articles.length != 0 ? item.articles : articlesList}
                  context={pageContext.images}

                />
                }
              </>
            )


          default:
            return ("")
        }


      })

      }

    </Layout>
  )
}

export default IndexPage